/**
 * Helper to cast query params into the given type.
 */
export const getQueryCaster = (
  query: Record<string, string | string[] | undefined>
) => {
  const parse = <T>(key: string, parser: (value: string) => T) => {
    const val = query[key]
    if (val === undefined) {
      return null
    }

    if (Array.isArray(val)) {
      return parser(val.join(","))
    }

    return parser(val)
  }

  const type =
    <T>(parser: (value: string) => T) =>
    (key: string) =>
      parse(key, parser)

  return {
    string: type((v) => v),
    boolean: type((v) => v === "true"),
    number: type((v) => (isNaN(parseInt(v)) ? null : parseInt(v))),
    list: type((v) => v.split(",")),
  }
}

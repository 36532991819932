import Box from "@mui/material/Box"

interface Props {
  searchInput?: JSX.Element
  sortMenu?: JSX.Element
  filterMenu: JSX.Element
  disableTopPadding?: boolean
  disableBorderTop?: boolean
  borderBottom?: boolean
  paddingBottom?: boolean
  disableMarginBottom?: boolean
}

const DataGridHeader = ({
  searchInput,
  sortMenu,
  filterMenu,
  disableBorderTop,
  disableTopPadding,
  borderBottom,
  paddingBottom,
  disableMarginBottom,
}: Props) => {
  return (
    <Box
      display="flex"
      sx={{
        mb: disableMarginBottom ? 0 : 2.5,
        pt: disableTopPadding ? 0 : 2.5,
        borderTop: disableBorderTop ? "none" : "1px solid #EEEEEE",
        borderBottom: borderBottom ? "1px solid #EEEEEE" : "none",
        paddingBottom: paddingBottom ? 2.5 : 0,
      }}
      justifyContent="space-between"
    >
      <div>{searchInput && searchInput}</div>
      <Box display="flex" justifyContent="flex-start" gap="10px">
        {sortMenu}
        {filterMenu}
      </Box>
    </Box>
  )
}

export default DataGridHeader

import Head from "next/head"

interface Props {
  children: React.ReactNode
  replaceTemplate?: boolean
}

const HTMLTitle = ({ children, replaceTemplate = false }: Props) => {
  let title = children?.toString() || ""
  if (!replaceTemplate) title += " <> Seam"
  return (
    <Head>
      <title>{title}</title>
    </Head>
  )
}

export default HTMLTitle

import { Button as MuiButton } from "@mui/material"
import { styled } from "@mui/system"

import { colorDefaults } from "lib/mui"

export const SortingButton = styled(MuiButton)(
  () =>
    `
  align-items: center;
  background-color: ${colorDefaults.background.gray};
  border: 1px solid ${colorDefaults.background.gray};
  border-radius: 6px;
  color: ${colorDefaults.text.textDefaultColor};
  display: inline-flex;
  font-size: 14px;
  height: 40px;
  padding 0 14px;
  text-transform: none;
  &:hover {
    background-color: ${colorDefaults.background.bgA};
    border-color: ${colorDefaults.text.textGray3};
  }
  &.active {
    background-color: ${colorDefaults.white};
    border-color: ${colorDefaults.text.hyperlink};
  }
  &.applied {
    background-color: ${colorDefaults.background.lightHyperlink};
    border-color: ${colorDefaults.text.hyperlink};
  }
  &:disabled {
    background-color: ${colorDefaults.background.gray};
    border-color: ${colorDefaults.background.gray};
    color: ${colorDefaults.text.textGray2};
  }
  `
)

export default SortingButton

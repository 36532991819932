import { useState } from "react"
import { BsCheck, BsXLg } from "react-icons/bs"
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuProps,
  Typography,
} from "@mui/material"
import { styled } from "@mui/system"
import classnames from "classnames"

import SortingButton from "components/interactive/SortingButton"

import { colorDefaults } from "lib/mui"

type Option<T> = {
  count?: number | string | bigint
  key: T
  icon?: (props: any) => JSX.Element
  label: string
}

const Menu = styled((props: MenuProps) => (
  <MuiMenu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(
  ({ theme }) =>
    `
  & .MuiPaper-root {
    border-radius: 6px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 2px 16px 2px rgba(15, 22, 28, 0.15);
    margin-top: ${theme.spacing(1)};
  }
  `
)

const MenuItem = styled(MuiMenuItem)(
  `
  &.Mui-disabled {
    opacity: 0.38 !important;
  }
  & .MuiMenuItem-root {
    display: inline-flex;
    justify-content: space-between;
    padding-right: 10px;
  }
  & .MuiListItemIcon-root {
    min-width: 24px;
  }
  `
)

export interface Props<T> {
  disabled?: boolean
  onSelect: (optionKey: T | null) => void
  options: Option<T>[]
  hideIcon?: boolean
  title?: string
  value: string | null
}

export function FilterMenu<T>({
  disabled,
  hideIcon,
  onSelect,
  options,
  title,
  value,
}: Props<T>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    if (event.target.localName == "path" || event.target.localName == "svg")
      return // if clicking on X
    setAnchorEl(!anchorEl ? event.currentTarget : null)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleExit = (event: any) => {
    event.stopPropagation()
    selectOption(null)
    setAnchorEl(null)
  }
  const selectOption = (optionKey: T | null) => {
    onSelect(optionKey)
    setAnchorEl(null)
  }

  return (
    <div>
      <SortingButton
        id="filtering-button"
        aria-controls={open ? "filtering-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={classnames({ active: open, applied: !!value })}
        disabled={disabled}
        onClick={handleClick}
        sx={{ zIndex: !!value ? 2000 : 1 }}
      >
        <Typography fontSize={14} fontWeight="600" mr={0.5}>
          Filter
        </Typography>
        <Typography fontSize={14}>
          {options.find((o) => o.key === value)?.label || "None"}
        </Typography>
        {value && (
          <Box ml={1.5} onClick={handleExit}>
            <BsXLg color={colorDefaults.text.textGray1} size="12px" />
          </Box>
        )}
      </SortingButton>
      <Menu
        anchorEl={anchorEl}
        id="filtering-menu"
        onClose={handleClose}
        open={open}
        MenuListProps={{
          "aria-labelledby": "filtering-button",
        }}
      >
        {title && (
          <MenuItem disabled>
            <ListItemText>{title}</ListItemText>
          </MenuItem>
        )}
        {options.map(({ count, key, icon: Icon, label }) => (
          <MenuItem
            disabled={count != disabled && count == 0}
            key={key as string}
            onClick={() => selectOption(key)}
            value={key as string}
          >
            {Icon && (
              <ListItemIcon sx={{ marginRight: "12px" }}>
                <Icon size="18px" />
              </ListItemIcon>
            )}
            <ListItemText>
              {count == undefined ? label : `${label} (${count})`}
            </ListItemText>
            <ListItemIcon
              sx={{
                marginLeft: "24px",
                minWidth: "min-content",
              }}
            >
              {key === value && <BsCheck size="24px" />}
            </ListItemIcon>
          </MenuItem>
        ))}
        {value && <Divider />}
        {value && (
          <MenuItem onClick={() => selectOption(null)}>
            {!hideIcon && <ListItemIcon sx={{ marginRight: "12px" }} />}
            <ListItemText>Remove filter</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default FilterMenu

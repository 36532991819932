import { createContext, useContext, useEffect, useMemo } from "react"
import { useRouter } from "next/router"

import { SearchState } from "hooks/search/useSearchState"
import useUrlSearchState from "hooks/search/useUrlSearchState"
import { useSetQueryParamKey } from "hooks/url/useSetQueryParam"

import { SearchInputProps } from "components/form/SearchInput"

import { handleString } from "lib/inputs"
import { getQueryCaster } from "lib/url"

interface Props {
  children: JSX.Element
  defaultSortKey: string
}

interface UrlQueryContextProps {
  search: SearchState
  sortKey: string
  setSortKey: (key: string) => void
  filterKey: string
  setFilterKey: (key: string | null) => void
  query: string
  searchInputProps: Pick<
    SearchInputProps,
    | "isSearching"
    | "onCancel"
    | "onClearValue"
    | "onChange"
    | "onFocus"
    | "onBlur"
    | "value"
  >
}

const UrlQueryContext = createContext<UrlQueryContextProps | undefined>(
  undefined
)

export const UrlQueryProvider = ({ children, defaultSortKey }: Props) => {
  const search = useUrlSearchState()
  const setQueryParamKey = useSetQueryParamKey()
  const urlParams = useUrlParams()

  const sortKey = urlParams.sort || defaultSortKey

  const query = buildQuery({
    sortKey,
    filterKey: urlParams.filter,
  })

  const searchInputProps = {
    isSearching: search.isActive,
    onCancel: search.cancel,
    onClearValue: search.clear,
    onChange: handleString(search.setTerm),
    onFocus: search.start,
    onBlur: search.handleBlur,
    value: search.term,
  }

  return (
    <UrlQueryContext.Provider
      value={{
        search,
        sortKey,
        setSortKey: setQueryParamKey("sort"),
        filterKey: urlParams.filter,
        setFilterKey: setQueryParamKey("filter"),
        query,
        searchInputProps,
      }}
    >
      {children}
    </UrlQueryContext.Provider>
  )
}

const useUrlParams = () => {
  const { query } = useRouter()

  return useMemo(() => {
    const castedQuery = getQueryCaster(query)

    return {
      sort: castedQuery.string("sort") || "",
      filter: castedQuery.string("filter") || "",
    }
  }, [query])
}

export const useUrlQuery = () => {
  const context = useContext(UrlQueryContext)
  if (context === undefined) {
    throw new Error("useUrlQuery must be used within <UrlQueryProvider/>")
  }

  return context
}

const buildQuery = (params: { sortKey: string; filterKey: string }) => {
  const { sortKey, filterKey } = params
  const options = []

  if (sortKey) {
    // TODO: add ability to set sort direction, ie. asc/desc.
    options.push(`sort:${sortKey}`)
  }

  if (filterKey) {
    options.push(filterKey)
  }
  return options.join(" ")
}
export default UrlQueryProvider

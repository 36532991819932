import { useEffect } from "react"
import { useRouter } from "next/router"

import { SearchState, useSearchState } from "hooks/search/useSearchState"
import { useSetQueryParam } from "hooks/url/useSetQueryParam"

import { getQueryCaster } from "lib/url"

const useUrlSearchState = (): SearchState => {
  const set = useSetQueryParam()
  const { query } = useRouter()
  const castedQuery = getQueryCaster(query)
  const urlValue = castedQuery.string("search")

  const search = useSearchState(null)
  const { term, setTerm, debouncedTerm } = search

  // Load current url value if one hasn't been set/loaded.
  useEffect(() => {
    if (urlValue && term === null) {
      setTerm(urlValue)
    }
  }, [urlValue, term, setTerm])

  // Update URL. We only want to update the url after a delay because
  // we wouldn't want to trigger a search request on every keystroke.
  useEffect(() => {
    if (debouncedTerm === null) {
      return
    }

    if (debouncedTerm === "" && urlValue === null) {
      return
    }

    if (debouncedTerm !== urlValue) {
      set("search", debouncedTerm)
    }
  }, [urlValue, debouncedTerm, set])

  return search
}

export default useUrlSearchState

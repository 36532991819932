import { useCallback, useMemo } from "react"
import { useRouter } from "next/router"

/**
 * Updates a single query param.
 *
 * Example: `?foo=bar`
 *
 * ```js
 * const set = useSetQueryParam()
 * set('foo', 'quex') // ?foo=quex
 * ```
 */
export const useSetQueryParam = () => {
  const { set, update } = useQuerySetter()

  return (key: string | Record<string, string>, val?: any) => {
    if (typeof key === "object") {
      for (const [param, val] of Object.entries(key)) {
        set(param, val)
      }
    } else {
      set(key, val)
    }

    update()
  }
}

/**
 * Curried version of useSetQueryParam() that accepts key/val as separate calls.
 */
export const useSetQueryParamKey = () => {
  const set = useSetQueryParam()
  return (key: string) => (val: string | null | undefined) => set(key, val)
}

// Wrap Next.js' router that allows us to set, and update separately. Useful for
// when we might want to set multiple query params at once, but don't want to
// update the url multiple times.
const useQuerySetter = () => {
  const { query, replace } = useRouter()
  const updated = useMemo(() => ({ ...query }), [query])

  const set = useCallback(
    (key: string, val: string) => {
      if (val === "" || val === undefined || val === null) {
        delete updated[key]
      } else {
        updated[key] = val
      }
    },
    [updated]
  )

  const update = () => {
    replace({
      query: updated,
    })
  }

  return { set, update }
}

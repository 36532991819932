import { useState } from "react"
import { BsCheck } from "react-icons/bs"
import {
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuProps,
  Typography,
} from "@mui/material"
import { styled } from "@mui/system"
import classnames from "classnames"

import SortingButton from "components/interactive/SortingButton"

type Option<T> = {
  key: T
  label: string
}

const Menu = styled((props: MenuProps) => (
  <MuiMenu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(
  ({ theme }) =>
    `
  & .MuiPaper-root {
    border-radius: 6px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 2px 16px 2px rgba(15, 22, 28, 0.15);
    margin-top: ${theme.spacing(1)};
  }
  `
)

const MenuItem = styled(MuiMenuItem)(
  `
  & .MuiMenuItem-root {
    display: inline-flex;
    justify-content: space-between;
    padding-right: 10px;
  }
  & .MuiListItemIcon-root {
    margin-left: 24px;
    min-width: 24px;
  }
  `
)

export interface Props<T> {
  disabled?: boolean
  onSelect: (optionKey: T) => void
  options: Option<T>[]
  value: string
}

export function SortingMenu<T>({
  disabled,
  onSelect,
  options,
  value,
}: Props<T>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const selectOption = (optionKey: any) => {
    onSelect(optionKey)
    handleClose()
  }

  return (
    <div>
      <SortingButton
        id="sorting-button"
        aria-controls={open ? "sorting-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={classnames({ active: open })}
        disabled={disabled}
        onClick={handleClick}
      >
        <Typography fontSize={14} fontWeight="600" mr={0.5}>
          Sort
        </Typography>
        <Typography fontSize={14}>
          {options.find((o) => o.key === value)?.label}
        </Typography>
      </SortingButton>
      <Menu
        anchorEl={anchorEl}
        id="sorting-menu"
        onClose={handleClose}
        open={open}
        MenuListProps={{
          "aria-labelledby": "sorting-button",
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.key as string}
            onClick={() => selectOption(option.key)}
            value={option.key as string}
          >
            <ListItemText sx={{ fontSize: "16px" }}>
              {option.label}
            </ListItemText>
            <ListItemIcon
              sx={{
                minWidth: "min-content",
              }}
            >
              {option.key === value && <BsCheck size="24px" />}
            </ListItemIcon>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default SortingMenu

import { BsSearch, BsXLg } from "react-icons/bs"
import { FormControlUnstyledProps } from "@mui/base/FormControlUnstyled"
import InputUnstyled, { inputUnstyledClasses } from "@mui/base/InputUnstyled"
import { Box, IconButton, Link } from "@mui/material"
import { styled } from "@mui/system"

import TextInput from "components/form/TextInput"

import { colorDefaults } from "lib/mui"

export const CustomInput = styled(InputUnstyled)(
  () => `
  border-radius: 8px;
  color: ${colorDefaults.text.textDefaultColor};
  background: ${colorDefaults.background.gray};
  border: 1px solid ${colorDefaults.background.gray};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: min-content;
  &.filled {
    background-color: white;
    border-color: ${colorDefaults.text.textGray3};
  }
  &:hover {
    background-color: ${colorDefaults.background.bgA};
    border-color: ${colorDefaults.text.textGray3};
  }
  &.focused {
    background-color: white;
    border-color: ${colorDefaults.text.hyperlink};
    box-shadow: 0px 0px 0px 3px rgba(182, 242, 255, 0.4);
  }

  .${inputUnstyledClasses.input} {
    display: inline-block;
    font-size: 14px;
    font-family: inherit;
    font-weight: 400;
    border: none;
    outline: 0;
    background-color: transparent;
    width: 140px;
    padding: 9px 0px;
  }
  `
)

const InputAdornment = styled("div")`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  &.clear {
    color: ${colorDefaults.text.textGray1};
    svg {
      color: ${colorDefaults.text.textGray1};
    }
    &:hover svg {
      color: ${colorDefaults.text.textGray3};
    }
  }
`

const CancelLink = styled(Link)(
  `
  color: ${colorDefaults.text.hyperlink};
  font-size: 14px;
  line-height: 0;
  text-decoration: none;
  `
)

export interface SearchInputProps extends FormControlUnstyledProps {
  isSearching: boolean
  onCancel: () => any
  onClearValue: () => any
  placeholder: string
}

const SearchInput = ({
  isSearching,
  onCancel,
  onClearValue,
  ...props
}: SearchInputProps) => (
  <Box display="flex" alignItems="center" flexDirection="row" flexWrap="nowrap">
    <TextInput
      startAdornment={
        <InputAdornment>
          <BsSearch color={colorDefaults.text.hintTextColor} size="12px" />
        </InputAdornment>
      }
      endAdornment={({ filled }) => (
        <InputAdornment>
          {filled && (
            <IconButton
              aria-label="clear search field"
              className="clear"
              onClick={onClearValue}
            >
              <BsXLg color={colorDefaults.text.textGray1} size="12px" />
            </IconButton>
          )}
        </InputAdornment>
      )}
      {...props}
    />
    {isSearching && (
      <Box component="span" ml={1}>
        <CancelLink onClick={onCancel}>Cancel</CancelLink>
      </Box>
    )}
  </Box>
)

export default SearchInput
